
import projectConstants from '~/store/project/-constants'

export default {
  name: 'MoleculePopupHeader',

  data() {
    return {
      hasRangesInitialised: false,
      showFilters: false,
      locationOptions: [],
      floorOptions: [],
      surfaceOptions: [],
      // noRoomsOptions: [],
      spaceTypeOptions: [],
      availabilityOptions: [],
      orientationOptions: [],
      balconyOptions: [],
      lakeViewOptions: [],
      sliderSurfaceValues: [0, 0],
      sliderPriceValues: [0, 0],
      surfaceRange: [0, 0],
      priceRange: [0, 0],
      selectedAmenities: []
    }
  },
  computed: {
    computedAmenities() {
      const amenitiesSet = {}
      const allAmenities = this.$store.state.base.landlordDetails.amenities

      this.spaces?.forEach((sp) => {
        sp.amenities.forEach((am) => {
          amenitiesSet[am.id] = this.$tdb(
            allAmenities.find((allAm) => allAm.id === am.id)?.translation.label || {}
          )
        })
      })
      return Object.entries(amenitiesSet).map(([uuid, textValue]) => ({
        uuid,
        textValue
      }))
    },
    filters() {
      return this.$store.state.project.filters
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    projects() {
      return this.$store.state.base.landlordDetails.projects
    },
    buildings() {
      return this.$store.state.base.landlordDetails.buildings
    },
    floors() {
      return this.$store.state.base.landlordDetails.floors
    },
    spaces() {
      return this.$store.state.base.landlordDetails.spaces
    },
    totalAvailableSpacesText() {
      const result = this.$store.state.project?.availabilityResults?.availableSpaces || 0
      return this.$tc('resultsFound', result)
    },
    currency() {
      return this.$store.state.base.meta.generalConfig.baseCurrencySymbol || '€'
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    activeFilterslen() {
      let count = 0
      for (const type in this.filters) {
        const val = this.filters[type]
        if (
          ![
            'location',
            'floors',
            // 'noRooms',
            'spaceType',
            'orientation',
            // 'surfaceRange',
            // 'priceRange',
            'priceRangeUniverse',
            'surfaceRangeUniverse',
            'amenities'
          ].includes(type)
        ) {
          continue
        }
        // if (type === 'spaceType') {
        //   if (val && val.id !== -1) {
        //     count++
        //   }
        // } else if (type === 'priceRange') {
        if (type === 'priceRangeUniverse') {
          if (
            val &&
            val.length &&
            (val[0] !== this.priceRange[0] || val[1] !== this.priceRange[1])
          ) {
            count++
          }
        } else if (type === 'surfaceRangeUniverse') {
          if (
            val &&
            val.length &&
            (val[0] !== this.surfaceRange[0] || val[1] !== this.surfaceRange[1])
          ) {
            count++
          }
        } else if (type === 'orientation' || type === 'location') {
          if (val && val !== 'all') {
            count++
          }
        } else if (Array.isArray(val) && !['priceRange', 'surfaceRange'].includes(type)) {
          if (val.length > 0) {
            count++
          }
        } else if (val !== -1) {
          count++
        }
      }

      return count
    },
    spaceTypes() {
      const existingSpaceTypesSet = new Set(
        this.spaces.map((space) =>
          space.available &&
          typeof space.space_type_id !== 'undefined' &&
          space.space_type_id !== null
            ? space.space_type_id
            : -1
        )
      )

      return (
        this.$store.state.base.landlordDetails?.spaceTypes.filter((spaceType) =>
          Array.from(existingSpaceTypesSet).includes(spaceType.id)
        ) || []
      )
    },
    spaceTypeSelectValue() {
      return this.spaceTypeOptions.find((s) => s.id === this.filters.spaceType)
    },
    locale() {
      return this.$i18n?.locale || 'en'
    }
  },
  watch: {
    activeProject() {
      this.initFilterOptions()
    },
    buildings() {
      this.initFilterOptions()
    },
    spaces: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.hasRangesInitialised = false
        this.setSurfaceAndPriceOptions()
        this.filterSpaces()
        this.$emit('filtered-spaces', val)
      }
    },
    '$store.state.project.filters': {
      deep: true,
      handler: function () {
        this.filterSpaces()
      }
    },
    sliderSurfaceValues() {
      this.handleFilterChange(this.sliderSurfaceValues, 'surfaceRangeUniverse')
    },
    sliderPriceValues() {
      this.handleFilterChange(this.sliderPriceValues, 'priceRangeUniverse')
    },
    '$i18n.locale'() {
      this.initFilterOptions()
    },
    spaceTypeSelectValue: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (typeof this.spaceTypeSelectValue === 'undefined') {
          this.$store.dispatch(
            projectConstants.withNamespace(projectConstants.action.CHANGE_FILTER_STATE),
            {
              value: -1,
              type: 'spaceType'
            }
          )
        }
      }
    }
  },
  mounted() {
    this.initFilterOptions()
  },
  methods: {
    toggleShowFilters() {
      this.showFilters = !this.showFilters
    },
    initFilterOptions() {
      this.setLocationsOptions()
      this.setFloorOptions()
      this.setAvailabilityOptions()
      this.setOrientationOptions()
      // this.setNoRoomsOptions()
      this.setAmenities()
      this.setSpaceTypeOptions()
    },
    setLocationsOptions() {
      const locationOptions = []
      this.projects.forEach((p, i) => {
        locationOptions.push({ text: p.address, id: p.address })
      })
      locationOptions.sort(function (a, b) {
        if (a.text < b.text) {
          return -1
        }
        if (a.text > b.text) {
          return 1
        }
        return 0
      })
      locationOptions.unshift({ text: this.$t('all'), id: 'all' })
      this.locationOptions = locationOptions
    },
    setFloorOptions() {
      const floors = []
      const floorOptions = []
      this.floors.forEach((f, i) => {
        if (f) {
          if (!floors.includes(f.no)) {
            floorOptions.push({
              text: this.$t('floor', { floor: f.no }),
              value: f.no,
              sortValue: f.no
            })
            floors.push(f.no)
          }
        }
      })
      floorOptions.sort(function (a, b) {
        if (a.sortValue < b.sortValue) {
          return -1
        }
        if (a.sortValue > b.sortValue) {
          return 1
        }
        return 0
      })
      this.floorOptions = floorOptions
    },
    setAmenities() {
      this.$store.state.project.filters.balcony = false
      this.$store.state.project.filters.lakeView = false
    },
    // setNoRoomsOptions() {
    //   const noRooms = []
    //   const noRoomsOptions = [
    //     { text: this.$t('all'), id: -1, numberOfRooms: -1, spaceType: 'Standard' }
    //   ]
    //   const roomTypeMap = new Map()
    //   this.spaces.forEach((s, i) => {
    //     if (!noRooms.includes(s.number_of_rooms)) {
    //       roomTypeMap.set(s.number_of_rooms, new Set())
    //     }
    //     roomTypeMap.get(s.number_of_rooms).add(s.space_type)
    //     noRooms.push(s.number_of_rooms)
    //   })
    //
    //   roomTypeMap.forEach((spaceTypes, numRooms) => {
    //     spaceTypes.forEach((spaceType) => {
    //       noRoomsOptions.push({
    //         text:
    //           spaceType === 'Standard'
    //             ? this.$tc('rooms', numRooms)
    //             : this.$tc(spaceType, spaceType),
    //         id: spaceType === 'Standard' ? `${spaceType}-${numRooms}` : `${spaceType}`,
    //         numberOfRooms: numRooms,
    //         spaceType
    //       })
    //     })
    //   })
    //   noRoomsOptions.sort(function (a, b) {
    //     if (a.numberOfRooms < b.numberOfRooms) {
    //       return -1
    //     }
    //     if (a.numberOfRooms > b.numberOfRooms) {
    //       return 1
    //     }
    //     return 0
    //   })
    //
    //   this.noRoomsOptions = noRoomsOptions
    // },
    setSpaceTypeOptions() {
      const result = this.spaceTypes.map((spaceType) => {
        const translation = spaceType.space_type_translations.find((t) => t.lang === this.locale)
        return {
          text: translation?.name || spaceType.name,
          id: spaceType.id
        }
      })
      this.spaceTypeOptions = [{ text: this.$t('all'), id: -1 }, ...result]
    },
    setAvailabilityOptions() {
      this.availabilityOptions = [
        { text: 'Any', id: '' },
        { text: 'On sale', id: true },
        { text: 'Sold', id: false }
      ]
    },
    setOrientationOptions() {
      const orientation = []
      const orientationOptions = []
      this.spaces.forEach((s, i) => {
        if (!orientation.includes(s.orientation)) {
          orientationOptions.push({
            text: this.$t(`${s.orientation}`),
            id: s.orientation
          })
          orientation.push(s.orientation)
        }
      })
      orientationOptions.sort(function (a, b) {
        if (a.text < b.text) {
          return -1
        }
        if (a.text > b.text) {
          return 1
        }
        return 0
      })
      orientationOptions.unshift({ text: this.$t('all'), id: 'all' })
      this.orientationOptions = orientationOptions
    },
    filterSpaces() {
      let filteredSpaces = [...this.spaces]

      if (!this.filters.showSoldApartments && !this.filters.showOnlySoldApartments) {
        filteredSpaces = filteredSpaces.filter((sp) => sp.available)
      }
      if (this.filters.showOnlySoldApartments) {
        filteredSpaces = filteredSpaces.filter((sp) => !sp.available)
      }
      if (this.filters.building !== -1) {
        filteredSpaces = filteredSpaces.filter(
          (s) => Number(s.building_id) === Number(this.filters.building)
        )
      }
      if (this.filters.floors.length && !this.filters.floors.includes('')) {
        filteredSpaces = filteredSpaces.filter((s) =>
          this.filters.floors.includes(Number(s.floor.no))
        )
      }
      // if (this.filters.noRooms.id !== -1) {
      //   filteredSpaces = filteredSpaces.filter(
      //     (s) =>
      //       Number(s.number_of_rooms) === Number(this.filters.noRooms.numberOfRooms) &&
      //       s.space_type === this.filters.noRooms.spaceType
      //   )
      // }
      if (this.filters.spaceType !== -1) {
        filteredSpaces = filteredSpaces.filter((s) => s.space_type_id === this.filters.spaceType)
      }
      if (this.filters.orientation !== 'all') {
        filteredSpaces = filteredSpaces.filter((s) => s.orientation === this.filters.orientation)
      }
      if (this.filters.surfaceRangeUniverse.length > 0) {
        filteredSpaces = filteredSpaces.filter((s) => {
          if (
            this.betweenRange(
              s.util_sqm,
              this.filters.surfaceRangeUniverse[0],
              this.filters.surfaceRangeUniverse[1]
            )
          ) {
            return s
          }
          return false
        })
      }
      if (this.filters.priceRangeUniverse.length > 0) {
        filteredSpaces = filteredSpaces.filter((s) => {
          if (
            this.betweenRange(
              s.price,
              this.filters.priceRangeUniverse[0],
              this.filters.priceRangeUniverse[1]
            )
          ) {
            return s
          }
          return false
        })
      }
      if (this.filters.amenities?.length > 0) {
        filteredSpaces = filteredSpaces.filter((s) => {
          const result = s.amenities
            .map((am) => am.id)
            .filter((am) => this.filters.amenities.includes(am.toString()))
          return result.length > 0
        })
      }

      this.filteredSpaces = filteredSpaces
      this.$emit('filtered-spaces', filteredSpaces)
    },
    handleFilterChange(selectedItem, type, value) {
      let selectedValue = selectedItem
      if (value) {
        selectedValue = value
      }
      // if (type === 'noRooms') {
      //   selectedValue = selectedItem
      // }
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.CHANGE_FILTER_STATE),
        {
          value: selectedValue,
          type
        }
      )
    },
    handleClearFilters() {
      this.$store.dispatch(projectConstants.withNamespace(projectConstants.action.RESET_FILTERS))
      this.initFilterOptions()
      this.setSurfaceAndPriceOptions()
    },
    setSurfaceAndPriceOptions() {
      this.surfaceRange = this.initSurfaceRange()
      this.sliderSurfaceValues = this.surfaceRange

      this.priceRange = this.initPriceRange()
      this.sliderPriceValues = this.priceRange
    },
    initSurfaceRange() {
      if (!this.spaces?.length) return [0, 0]
      let minSurface = this.spaces?.[0]?.util_sqm ?? 0
      let maxSurface = this.spaces?.[0]?.util_sqm ?? 0
      this.spaces.forEach((s, i) => {
        // TODO: [Undo modifications if you have to calculate total_useful_area by including garden and balcony surfaces]
        // const balconySurface =
        //   s.units?.find((u) => u.roomType.name.toLowerCase() === 'Balcony'.toLowerCase())
        //     ?.surface || 0
        // const gardenSurface =
        //   s.units?.find((u) => u.roomType.name.toLowerCase() === 'Garden'.toLowerCase())?.surface ||
        //   0
        // const minUtilSurface = s.util_sqm + balconySurface + gardenSurface
        // const maxUtilSurface = s.util_sqm + balconySurface + gardenSurface
        const minUtilSurface = s?.util_sqm ?? 0
        const maxUtilSurface = s?.util_sqm ?? 0
        if (minUtilSurface < minSurface) {
          minSurface = minUtilSurface
        } else if (maxUtilSurface > maxSurface) {
          maxSurface = maxUtilSurface
        }
      })

      return [Math.floor(minSurface), Math.ceil(maxSurface)]
    },
    initPriceRange() {
      if (!this.spaces?.length) return [0, 0]
      let minPrice = this.spaces[0].price
      let maxPrice = this.spaces[0].price
      this.spaces.forEach((s, i) => {
        if (s.price < minPrice) {
          minPrice = s.price
        } else if (s.price > maxPrice) {
          maxPrice = s.price
        }
      })

      return [Math.floor(minPrice), Math.ceil(maxPrice)]
    },
    betweenRange(x, min, max) {
      return x >= min && x <= max
    }
  }
}
