
import universeConstants from '~/store/universe/-constants'

export default {
  name: 'MoleculePopupResult',
  props: {
    project: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    defaultOpen: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    generalConfig() {
      return this.$store.state.base.meta.generalConfig
    },
    parkingMetadata() {
      const { parkingMetadata } = this.generalConfig
      const findParkingMetadataByProject = parkingMetadata?.[this.project.slug]
        ? parkingMetadata[this.project.slug]
        : null
      if (findParkingMetadataByProject) {
        return {
          availableNo: findParkingMetadataByProject.availableNo ?? 0,
          totalNo: findParkingMetadataByProject.totalNo ?? 0
        }
      }
      return {
        availableNo: 0,
        totalNo: 0
      }
    },
    activeProject() {
      return this.$store.state.project.activeProject || null
    },
    projects() {
      return this.$store.state.base?.landlordDetails?.projects || []
    },
    buildings() {
      return (
        this.$store.state.base?.landlordDetails?.buildings?.filter(
          (bl) => bl.project_id === this.project.id
        ) || []
      )
    },
    floors() {
      return (
        this.$store.state.base?.landlordDetails?.floors?.filter((fl) =>
          this.buildings.some((bl) => bl.id === fl.building_id)
        ) || []
      )
    },
    universe() {
      return this.$store.state?.universe?.universe || null
    },
    cards() {
      return this.$store.state?.universe?.cards || []
    },
    open() {
      let open = this.defaultOpen
      const card = this.cards?.find((c) => c.project_slug === this.project.slug) || null
      if (card) {
        open = card.open
      }
      return open
    },
    spaces() {
      return (
        this.$store.state.base?.landlordDetails?.spaces?.filter(
          (sp) => sp.project_id === this.project.id
        ) || []
      )
    },
    availableSpaces() {
      return this.spaces?.filter((s) => s.available) || []
    }
  },
  methods: {
    handleProjectClick() {
      const foundCardToUpdate =
        this.cards?.find((c) => c.project_slug === this.project.slug) || null
      const otherCards = this.cards?.filter((c) => c.project_slug !== this.project.slug) || []
      for (const card of otherCards) {
        this.updateCardState(card.mesh_id, false) // clear other cards state from universeStore
      }
      if (!foundCardToUpdate) return
      this.updateCardState(foundCardToUpdate.mesh_id, !foundCardToUpdate.open) // toggle clicked card open state
      this.highlightMapProject(foundCardToUpdate.project_slug, foundCardToUpdate.open)
      this.$parent?.$parent?.$parent?.$refs?.map?.centerMapByMarkerPosition(
        foundCardToUpdate.project_slug,
        foundCardToUpdate.open
      )
    },
    getBuildingsMaxFloors() {
      const floors = this.floors
      let maxFloor = 0
      if (floors) {
        floors.forEach((floor) => {
          maxFloor = floor.no > maxFloor ? floor.no : maxFloor
        })
      }
      return maxFloor
    },
    highlightMapProject(projectSlug, activate) {
      const foundMapPin =
        document.querySelector(`.static-marker[data-project="${projectSlug}"]`) || null
      if (!foundMapPin) return
      const otherMapPins = document.querySelectorAll(`.static-marker`) || []
      for (const otherMapPin of otherMapPins) {
        if (otherMapPin.classList.contains('selected')) {
          otherMapPin.classList.remove('selected')
        }
      }
      if (activate) {
        foundMapPin.classList.add('selected')
      } else {
        foundMapPin.classList.remove('selected')
      }
    },
    updateCardState(meshId, isOpen) {
      this.$store.dispatch(
        universeConstants.withNamespace(universeConstants.action.UPDATE_CARD_STATE),
        {
          mesh_id: meshId,
          open: isOpen
        }
      )
    }
  }
}
