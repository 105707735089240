
import projectConstants from '~/store/project/-constants'
import universeConstants from '~/store/universe/-constants'

export default {
  name: 'MoleculePopupResults',
  data() {
    return {
      listData: []
    }
  },
  computed: {
    projects() {
      return this.$store.state.project.projects
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.general.unitOfMeasure
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.general.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    modelMeshes() {
      if (
        !this.$store.state.base.meta.landingConfig.sections.find((o) => o.type === 'Universe')
          ?.extra?.modelMeshes
      ) {
        return (
          this.$store.state.base.meta.landingConfig.sections.find((o) => o.type === 'UniverseMap')
            ?.extra?.modelMeshes || []
        )
      }
      return (
        this.$store.state.base.meta.landingConfig.sections.find((o) => o.type === 'Universe')?.extra
          ?.modelMeshes || []
      )
    },
    filters() {
      return this.$store.state.project.filters
    },
    spacesByProjectId() {
      const spaces = {}
      this.$store.state.base.landlordDetails?.spaces?.forEach((sp) => {
        if (!spaces[sp.project_id]) {
          spaces[sp.project_id] = []
        }
        spaces[sp.project_id].push(sp)
      })
      return spaces
    }
  },
  watch: {
    '$store.state.project.projects': function () {
      this.refreshListData()
    },
    filters: {
      deep: true,
      handler: function (filters) {
        this.refreshListData()
      }
    }
  },
  mounted() {
    this.refreshListData()
  },
  beforeDestroy() {},
  methods: {
    handleHighlightTrigger(spaceId) {
      const targetSpace = document.querySelector(`[data-highlight-id="${spaceId}"]`)
      if (!targetSpace) {
        return
      }
      const parent = targetSpace.parentElement.parentElement
      const getSiblings = function (elem) {
        const descendants = elem.parentNode.children
        return Array.prototype.filter.call(descendants, function (sibling) {
          return sibling !== elem
        })
      }
      const simulateClick = function (elem) {
        // Create our event (with options)
        const evt = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
        // If cancelled, don't dispatch our event
        const canceled = !elem.dispatchEvent(evt)
      }
      const parentSiblings = getSiblings(parent)
      if (!parent.classList.contains('active')) {
        if (parentSiblings) {
          parentSiblings.length && simulateClick(parentSiblings[0])
        }
      }
      simulateClick(targetSpace)
    },
    refreshListData() {
      this.listData = []
      const projects = [...this.projects]
      // let filtersApplied = false;
      if (Array.isArray(projects)) {
        projects.forEach((p) => {
          if (this.filters.location !== 'all' && p.address !== this.filters.location) {
            return this.listData.push({
              ...p,
              spaces: []
            })
          }
          let filteredSpaces = this.spacesByProjectId[p.id] || []
          if (this.filters.floors.length && !this.filters.floors.includes('')) {
            filteredSpaces = filteredSpaces.filter((s) =>
              this.filters.floors.includes(Number(s.floor.no))
            )
          }
          // if (this.filters.noRooms.id !== -1) {
          //   filteredSpaces = filteredSpaces.filter(
          //     (s) =>
          //       Number(s.number_of_rooms) === Number(this.filters.noRooms.numberOfRooms) &&
          //       s.space_type === this.filters.noRooms.spaceType
          //   )
          // }
          if (this.filters.spaceType !== -1) {
            filteredSpaces = filteredSpaces.filter(
              (s) => s.space_type_id === this.filters.spaceType
            )
          }
          if (this.filters.orientation !== 'all') {
            filteredSpaces = filteredSpaces.filter(
              (s) => s.orientation === this.filters.orientation
            )
          }
          if (this.filters.surfaceRange.length > 0) {
            filteredSpaces = filteredSpaces.filter((s) => {
              if (
                this.betweenRange(
                  s.util_sqm,
                  this.filters.surfaceRange[0],
                  this.filters.surfaceRange[1]
                )
              ) {
                return s
              }
              return false
            })
          }
          if (this.filters.priceRange.length > 0) {
            filteredSpaces = filteredSpaces.filter((s) => {
              if (
                this.betweenRange(s.price, this.filters.priceRange[0], this.filters.priceRange[1])
              ) {
                return s
              }
              return false
            })
          }
          if (this.filters.amenities?.length > 0) {
            filteredSpaces = filteredSpaces.filter((s) => {
              const result = s.amenities
                .map((am) => am.id)
                .filter((am) => this.filters.amenities.includes(am.toString()))
              return result.length > 0
            })
          }

          this.listData.push({
            ...p,
            spaces: filteredSpaces
          })
        })
      }
      this.assignStoreCardProjects()
      this.getTotalResults()
    },
    betweenRange(x, min, max) {
      return x >= min && x <= max
    },
    getTotalResults() {
      let totalAvailableSpaces = 0
      if (Array.isArray(this.listData)) {
        this.listData.forEach((l) => {
          l.spaces.forEach((s) => {
            if (s.available) {
              totalAvailableSpaces++
            }
          })
        })
      }
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.LOAD_TOTAL_AVAILABLE_RESULTS),
        {
          availableSpaces: totalAvailableSpaces
        }
      )
    },
    assignStoreCardProjects() {
      this.$store.dispatch(
        universeConstants.withNamespace(universeConstants.action.INIT_PROJECTS_CARDS_STATE),
        {
          cards:
            this.listData?.map((l) => ({
              project_id: l.id,
              project_slug: l.slug,
              mesh_id: this.modelMeshes.find((m) => m.project_slug === l.slug)?.mesh_id || '',
              open: false
            })) || []
        }
      )
    }
  }
}
