
import AtomSelect from '../../atoms/common/AtomSelect'
import AtomIcon from '../../atoms/common/AtomIcon'

export default {
  name: 'MoleculeFitoutSelector',
  components: { AtomIcon, AtomSelect },
  props: {
    fitoutData: {
      required: true,
      type: Array,
      default: () => []
    },
    changeFitout: {
      required: true,
      type: Function,
      default: () => {}
    }
  },
  computed: {
    generalConfig() {
      return this.$store.state.base.meta.general
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    floorFitouts() {
      return this.generalConfig?.modelDisplaySettings[this.activeProject]?.floor?.fitoutsInfo || []
    },
    fitoutsOpts() {
      let newfitoutData = []
      let opts = [
        {
          text: 'Select one of the fit-outs to see how the space will look if fitted in certain ways or none to see it empty.',
          value: '',
          disabled: 'disabled'
        },
        { text: 'None', value: 'none' }
      ]
      if (this.fitoutData && this.fitoutData.length > 0) {
        newfitoutData = this.fitoutData.map(function (obj, index) {
          return {
            text: `Fitout ${index + 1}`,
            value: obj.value
          }
        })
        opts = [...opts, ...newfitoutData]
      }
      return opts
    }
  }
}
