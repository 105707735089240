
export default {
  name: 'MoleculePinExpand',
  props: {
    pinData: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    lang() {
      return this.$store.state.base.language
    },
    description() {
      const { description } = this.pinData
      if (!description) return
      return typeof description === 'string'
        ? description
        : description[this.lang] || `[missing ${this.lang} translation]`
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters.cdnBase}/${url}`
    }
  }
}
