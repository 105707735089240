
import modalConstants from '~/store/modal/-constants'

export default {
  name: 'MoleculeSendRequest',
  data() {
    return {
      isShown: false
    }
  },
  methods: {
    toggleShow() {
      this.isShown = !this.isShown
    },
    closeModal() {
      this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CLOSE_MODAL))
      document.body.classList.remove('disable-scroll')
    }
  }
}
