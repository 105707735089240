
export default {
  name: 'MoleculeContractDetailsContainer',
  props: {
    spaceData: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    contractShowcaseEnabled() {
      return (
        this.$store.getters.featuresForProject(this.activeProjectSlug)?.contractShowcase || false
      )
    }
  },
  methods: {}
}
